import './css/App.css';
import {useRef, useEffect, useState} from 'react'
import {useMediaQuery} from 'react-responsive';
import {motion, useAnimation} from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4'

//Icons
import { HiMenu } from "react-icons/hi";

//Images
import initialsLogo from './images/TPSLogo+Initials.png'
import tpsFullLogo from './images/TPSLogo+Name.png'
import { ReactComponent as Stars } from './images/Stars.svg';
import { ReactComponent as Planet } from './images/TPS-Planet.svg';
import neonCheckMark from './images/neon-checkmark.png';
import webdevIcon from './images/webdev-icon.png';
import mobileAppIcon from './images/mobileapp-icon.png';
import applicationIcon from './images/application-icon.png';
import pythonIcon from './images/python-icon.png';
import aiIcon from './images/ai-icon.png';
import customIcon from './images/custom-icon.png';
import TPSGradient from './images/TPS-Gradient.png'
import CMBanner from './images/constellationProductBanner.jpg'
import MKWebBanner from './images/maddiekeyesweb.png'
import CFBanner from './images/crowdfreemobile.png'
import MindfulBanner from './images/mindfulPurchaseHeader.jpg'

//Videos
import whatwedoVideo from './videos/whatwedo-ANIM.mp4'

//Components
import MobileNavigationWindow from './Components/MobileNavigationWindow';
import SiteMap from './Components/SiteMap';
import MainNavBar from './Components/MainNavBar';

function App() {

  const vidRef = useRef();
  const [isLoaded, setLoading] = useState(false);
  const loadingModal = useAnimation();
  const navModalAnim = useAnimation();
  const [navModal, setNavModal] = useState(false);
  ReactGA.initialize("G-SZLZLTXLRV")

  const location = useLocation();
  
  const isMobile = useMediaQuery({
    query: '(max-width: 700px)'
  })

  const isHalfScreen = useMediaQuery({
    query: '(max-width: 1800px)'
  })

  useEffect(()=>{

    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home"
    });

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.65
    };

    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
          element.scrollIntoView({ behavior: "smooth" });
      }
    }

    vidRef.current.play();

    loadingModal.start("visible");
    navModalAnim.start("visible");

    loadingModal.start("hidden")

    function callbackFunc(entries, observerFour){
      entries.forEach(entry => {
        switch(entry.target.id){
          case "designSection":
            if(entry.isIntersecting){
              document.getElementById("designSection").classList.remove("hidden-container");
              document.getElementById("designSection").classList.add("fadeIn-anim");
              setTimeout(()=>{
                document.getElementById("innovateSection").classList.remove("hidden-container");
                document.getElementById("innovateSection").classList.add("fadeIn-anim");
              }, 250)

              setTimeout(()=>{
                document.getElementById("deliverSection").classList.remove("hidden-container");
                document.getElementById("deliverSection").classList.add("fadeIn-anim");
              }, 750)
            }
            break;
          case "card1":
            if(entry.isIntersecting){
              if(isMobile){
                document.getElementById("card1").classList.remove("hidden-container");
                document.getElementById("card1").classList.add("fadeIn-anim-left");
  
                document.getElementById("card2").classList.remove("hidden-container");
                document.getElementById("card2").classList.add("fadeIn-anim-right");
  
                document.getElementById("card3").classList.remove("hidden-container");
                document.getElementById("card3").classList.add("fadeIn-anim-left");
  
                document.getElementById("card4").classList.remove("hidden-container");
                document.getElementById("card4").classList.add("fadeIn-anim-right");
              }
              else{
                document.getElementById("card1").classList.remove("hidden-container");
                document.getElementById("card1").classList.add("fadeIn-anim-up");

                document.getElementById("card2").classList.remove("hidden-container");
                document.getElementById("card2").classList.add("fadeIn-anim-down");

                document.getElementById("card3").classList.remove("hidden-container");
                document.getElementById("card3").classList.add("fadeIn-anim-up");

                document.getElementById("card4").classList.remove("hidden-container");
                document.getElementById("card4").classList.add("fadeIn-anim-down");
              }
            }
            break;
          case "product1":
            if(entry.isIntersecting){
              document.getElementById("product1").classList.remove("hidden-container");
              document.getElementById("product1").classList.add("fadeIn-anim");

              document.getElementById("product2").classList.remove("hidden-container");
              document.getElementById("product2").classList.add("fadeIn-anim");

              document.getElementById("website1").classList.remove("hidden-container");
              document.getElementById("website1").classList.add("fadeIn-anim");

              document.getElementById("website2").classList.remove("hidden-container");
              document.getElementById("website2").classList.add("fadeIn-anim");
            }
            break;
          default:
            break;
        }
      });
    }

    let observer = new IntersectionObserver(callbackFunc, options);

    const allElements = document.querySelectorAll('.hidden-container');

    allElements.forEach((element)=>{observer.observe(element);});

  }, [loadingModal, navModalAnim, isMobile, location.hash])

  function onUpdate(latest){
    if(latest.opacity === 0){
      setLoading(true);
    }
  }

  function showHideModal(){
    if(navModal){
      navModalAnim.start("hidden").then(()=>{
        setNavModal(false);
      });
    }
    else{
      setNavModal(true);
      navModalAnim.start({x: 500});
    }
  }

  const scrollCarousel = (amount) => {
    const container = document.getElementById("explore-child-container-desktop");
    if (container) {
      container.scrollBy({ left: amount, behavior: "smooth" });
    }
  };
  

  const loadal = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 1}}}
  const naval = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 0.5}}}

  return (
    <div className="App" style={{overflowX: "hidden"}}>
      {!isLoaded && 
        <motion.div animate={loadingModal} variants={loadal} id="loadingCover" onUpdate={onUpdate}>
          <motion.img src={tpsFullLogo} id={isMobile ? "loading-cover-img-mobile" : "loading-cover-img"} animate={{opacity: [0, 1], transition: {repeat: Infinity, duration: 1.75, repeatType: "reverse"}}}/>
        </motion.div>
      }
      <header>
        {!isMobile && 
          <MainNavBar/>
        }
        {isMobile && 
          <nav className={"nav-mobile"}>
            <Link to={"/"} id="logo-link-container">
              <img src={initialsLogo} id={"nav-logo-mobile"} alt="Transparent Software Logo"/>
            </Link>
            <div id="nav-mobile-group-right">
              {/*<button onClick={()=>{window.location.href = window.location.origin + "/store"}} className="mobile-shop-button">Shop</button>*/}
              <HiMenu id={"hamburger-icon-mobile"} size={25} onClick={()=> showHideModal() }/>
            </div>
          </nav>
        }
      </header>
      <main>
        {isMobile && navModal && 
          <MobileNavigationWindow naval={naval} navModalAnim={navModalAnim}/>
        }
        <section id="landing">
          <div id={isMobile ? "landing-container-mobile" : "landing-container-desktop"}>
            <Stars id={isMobile ? 'landing-stars-mobile' : 'landing-stars-desktop'} />
            <Stars style={{transform: 'rotate(180deg)'}} id={isMobile ? 'landing-stars-mobile' : 'landing-stars-desktop'} />
            <motion.div animate={{opacity: [0, 1], transition: {duration: 1, delay: 0.5}}} id={isMobile ? "landing-text-container-mobile" : "landing-text-container-desktop"}>
              <img src={initialsLogo} alt={"Transparent Software Logo"}/>
              <h1>Building trust through technology</h1>
              <h3>Innovative, reliable, transparent solutions that <span className="alt-gradient-text-green">drive success.</span></h3>
              <div id={isMobile ? "landing-button-container-mobile" : "landing-button-container-desktop"}>
                <motion.button whileHover={{opacity: [1, 0.75]}} className={isMobile ? "tertiary-button-mobile" : "tertiary-button"}>
                  <p>Explore TPS</p>
                </motion.button>
                <motion.button whileHover={{opacity: [1, 0.75]}} className={isMobile ? "primary-button-mobile" : "primary-button"}>
                  <p className="alt-gradient-text-green" style={{textShadow: 'none'}}>Explore Your Idea</p>
                </motion.button>

              </div>
            </motion.div>
            <Planet id={isMobile ? "landing-planet-mobile" : "landing-planet-desktop"}/>
            <Planet style={{transform: isHalfScreen ? 'translate(-75%, 35%' : 'translate(-60%, 35%'}} id={isMobile ? "landing-planet-mobile" : "landing-planet-desktop"}/>
          </div>
        </section>
        <section id="whatwedo">
          <motion.div animate={{opacity: [0, 1], transition: {duration: 1, delay: 0.75}}} id={isMobile ? "whatwedo-container-mobile" : "whatwedo-container-desktop"}>
            <div id={isMobile ? "whatwedo-text-container-mobile" : "whatwedo-text-container-desktop"}>
              <h2>What <span style={{color: '#508B68'}}>We Do</span></h2>
              {isMobile && <div id={isMobile ? "whatwedo-graphic-container-mobile" : "whatwedo-graphic-container-desktop"}>
                <video className={isMobile ? "landing-video-mobile" : "landing-video-desktop"} src={whatwedoVideo} ref={vidRef} autoPlay muted loop playsInline webkit-playsinline/>
              </div>}
              <p>For both simple and complex software solutions, we offer a streamlined process to take your idea from concept to completion. We ensure every client understands their project with full <span className="alt-gradient-text-green">transparency</span> to avoid any confusion once everything is finished.</p>
              <div id={isMobile ? "checkmarks-container-mobile" : "checkmarks-container"}>
                <div>
                  <img src={neonCheckMark} alt="Checkmark"/>
                  <p>Design.</p>
                </div>
                <div>
                  <img src={neonCheckMark} alt="Checkmark"/>
                  <p>Innovate.</p>
                </div>
                <div>
                  <img src={neonCheckMark} alt="Checkmark"/>
                  <p>Execute.</p>
                </div>
                <div>
                  <img src={neonCheckMark} alt="Checkmark"/>
                  <p>Deliver.</p>
                </div>
              </div>
            </div>
            {!isMobile && <div id={isMobile ? "whatwedo-graphic-container-mobile" : "whatwedo-graphic-container-desktop"}>
              <video className={isMobile ? "landing-video-mobile" : "landing-video-desktop"} src={whatwedoVideo} ref={vidRef} autoPlay muted loop playsInline webkit-playsinline/>
            </div>}
          </motion.div>
        </section>
        <section id="whatweoffer">
          <div id={isMobile ? "whatweoffer-container-mobile" : "whatweoffer-container-desktop"}>
            <div id={isMobile ? "whatweoffer-header-container-mobile" : "whatweoffer-header-container"}>
              <img src={initialsLogo} alt="Transparent Software Logo"/>
              <h2>Services</h2>
            </div>
            <div id={isMobile ? "services-container-mobile" : "services-container-desktop"}>
              <motion.div whileHover={{scale: [1, 1.1]}}>
                <img src={webdevIcon} alt="Web Development Icon"/>
                <h4>Web Development Services</h4>
              </motion.div>
              <motion.div whileHover={{scale: [1, 1.1]}}>
                <img src={mobileAppIcon} alt="Mobile Development Icon"/>
                <h4>Mobile App Development</h4>
              </motion.div>
              <motion.div whileHover={{scale: [1, 1.1]}}>
                <img src={applicationIcon} alt="Application Development Icon"/>
                <h4>Application Software Services</h4>
              </motion.div>
              <motion.div whileHover={{scale: [1, 1.1]}}>
                <img src={pythonIcon} alt="Python Development Icon"/>
                <h4>Python Application and Automation</h4>
              </motion.div>
              <motion.div whileHover={{scale: [1, 1.1]}}>
                <img src={aiIcon} alt="AI/ML Integration Icon"/>
                <h4>AI & ML Integration Services</h4>
              </motion.div>
              <motion.div whileHover={{scale: [1, 1.1]}}>
                <img src={customIcon} alt="Custom Software Icon"/>
                <h4>Custom Software Solutions</h4>
              </motion.div>
            </div>
            <img src={TPSGradient} alt="" id={isMobile ? "services-gradient-mobile" : "services-gradient-desktop"}/>

          </div>
        </section>
        <section id="explore">
          <div id={isMobile ? "explore-container-mobile" : "explore-container-desktop"}>
            <h2>Our Work</h2>

            <button className="explore-nav-button explore-prev" onClick={() => scrollCarousel(-300)}>
              ‹
            </button>

            <div id="explore-child-container-desktop">
              {[ 
                {img: CMBanner, title: "Clash Marker", type: "Python Application Development", desc: "Clash Marker is a tool designed to take clash and viewpoint data from clash coordination software and translate it directly onto 2D & 3D design software.", btnText: "Explore", link: "/clashmarker"},
                {img: MKWebBanner, title: "Maddie Keyes Photography", type: "Website Design & Development", desc: "Beautifully elegant web design to reflect gorgeous photography from Maddie Keyes. Complete with simple photo upload and deletion to manage photos on the website.", btnText: "Visit", link: "https://maddiekeyes.netlify.app/"},
                {img: CFBanner, title: "CrowdFree", type: "Mobile Application Design & Development", desc: "CrowdFree is designed for college students to alert each other of different events around campus. Allowing them to either avoid or seek out events around campus.", ios: "https://apps.apple.com/us/app/crowdfree/id6737516256", android: "https://play.google.com/store/apps/details?id=com.tps_technology.leavemealone"},
                {img: MindfulBanner, title: "Mindful", type: "Mobile Application Development", desc: "Mindful is a scheduling application that utilizes research into the brain's behavior to schedule your day-to-day tasks. Allowing you to end the day less stressed and burnt out.", btnText: "iOS", link: "https://apps.apple.com/us/app/mindful/id1637254506"}
              ].map((project, index) => (
                <div key={index} id={isMobile ? "explore-item-container-mobile" : "explore-item-container-desktop"}>
                  <img src={project.img} alt={`${project.title} banner`} style={{objectPosition: isMobile && project.title.includes("Maddie Keyes") ? "0% 50%" : !isMobile && project.title.includes("Crowd") ? "0% 25%" : "0% 50%"}}/>
                  <h4>{project.title}</h4>
                  <h5>Type: {project.type}</h5>
                  <p>{project.desc}</p>
                  {project.link && (
                    <motion.button whileHover={{ opacity: [1, 0.75] }} onClick={() => {
                        if(project.link.includes("clashmarker")){ 
                          window.location.href = window.location.origin + project.link 
                        }
                        else{
                          window.open(project.link, "_blank")
                        }
                      }}>
                      <p>{project.btnText}</p>
                    </motion.button>
                  )}
                  {project.ios && project.android && (
                    <div style={{ display: 'flex', gap: '8px', width: '100%', justifyContent: 'flex-end'}}>
                      <motion.button whileHover={{ opacity: [1, 0.75] }} onClick={() => window.open(project.ios, "_blank")}>
                        <p>iOS</p>
                      </motion.button>
                      <motion.button whileHover={{ opacity: [1, 0.75] }} onClick={() => window.open(project.android, "_blank")}>
                        <p>Android</p>
                      </motion.button>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <button className="explore-nav-button explore-next" onClick={() => scrollCarousel(300)}>
              ›
            </button>
          </div>
        </section>

      </main>
      <SiteMap isMobile={isMobile}/>
    </div>
  );
}

export default App;
